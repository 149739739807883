import { isMobileDevice } from "./browser-utils.es6.js"
import { IrUtils } from "./utils.es13.js"

const PLATFORM_IS_DESKTOP = 'desktop';
const PLATFORM_IS_MOBILE = 'mobile';

/**
 * Get the base URL for the desktop website, potentially using a branded domain.
 * @param {string=} path additional path on the URL.
 * @return {string} url
 */
function getInfinityyDesktopUrl(path) {
	if (IrUtils.isNullyOrEmptyString(path)) { path = ''; }
	return window.siteSettings.InfinityyApiUrl + path;
}

function getInfinityyViewerUrl(options) {
	const initialUrl = window.pageInitialization.initialUrl;
	let url = initialUrl.setRootUrl(window.siteSettings.InfinityyApiUrl).cloneWithoutTemporaryParams();
	if (options?.emailAddress) {
		url = url.setLeadEmail(options.emailAddress);
	}
	if (options?.noRedirect) {
		url = url.setNoRedirect(true);
	}
	if (options?.contentOnlyLink) {
		url = url.cloneAsContentAndChatLink().cloneWithoutTemporaryParams();
	}
	return url.toString();
};

function getShareLink() {
	return getInfinityyViewerUrl({ contentOnlyLink: true });
}

function redirectViewerIfNecessary(pagePlatform) {
	const initialUrl = window.pageInitialization.initialUrl;
	const routeOtherPlatformTo = window.siteSettings?.Redirect?.RouteOtherPlatformTo;
	const devicePlatform = isMobileDevice() ? PLATFORM_IS_MOBILE : PLATFORM_IS_DESKTOP;

	if (!initialUrl.contentGuid && !initialUrl.brandSlug) {
		window.location = window.siteSettings.Redirect.DefaultWebsiteUrl;
		return true;
	}
	if (initialUrl.noRedirect) {
		return false;
	}
	if (routeOtherPlatformTo && devicePlatform != pagePlatform) {
		window.location = initialUrl.setRootUrl(routeOtherPlatformTo).setNoRedirect(true).toString();
		return true;
	}
	return false;
};

export { getInfinityyDesktopUrl, getInfinityyViewerUrl, getShareLink, redirectViewerIfNecessary, PLATFORM_IS_DESKTOP, PLATFORM_IS_MOBILE }
